// 电话 & 固定电话
export const REG_PHONE = /^1(3|4|5|6|7|8|9)\d{9}$/
export const REG_ALL_PHONE = /^1[3-9]\d{9}$|^852[569]\d{7}$|^8536\d{7}$/
export const REG_PHONE_FIXED = /^(\(\d{3,4}\)|\d{3,4}-)?\d{7,8}$/
// 验证码
export const REG_SMS = /^[0-9]{6}$/

export const REG_CHINESE = /[\u4E00-\u9FA5]/g
// 姓名
export const REG_USER_NAME = /^([\u4E00-\u9FA5\u3400-\u4DB5]{1}$|[\u4E00-\u9FA5\u3400-\u4DB5][\u4E00-\u9FA5\u3400-\u4DB5·]{0,18}[\u4E00-\u9FA5\u3400-\u4DB5]$|[a-zA-Z\s]{1,40})$/
// 学生认证学号
export const REG_STUDENT_NUMBER = /^[0-9a-zA-Z]{1,25}$/

export const REG_SHORT_NAME = /^([\u4E00-\u9FA5\u3400-\u4DB5]{1}$|[\u4E00-\u9FA5\u3400-\u4DB5][\u4E00-\u9FA5\u3400-\u4DB5·]{0,8}[\u4E00-\u9FA5\u3400-\u4DB5]$|[a-zA-Z\s]{1,20})$/

// 密码：8-20位小写字母、大写字母和数字的组合，不限制连续或相同的字母或数字
export const REG_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,20}$/
export const REG_CONTINUOUSLY_CHAR = /(?:01234|43210|12345|54321|23456|65432|34567|76543|45678|87654|56789|98765|67890|09876|ABCDE|EDCBA|BCDEF|FEDCB|CDEFG|GFEDC|DEFGH|HGFED|EFGHI|IHGFE|FGHIJ|JIHGF|GHIJK|KJIHG|HIJKL|LKJIH|IJKLM|MLKJI|JKLMN|NMLKJ|KLMNO|ONMLK|LMNOP|PONML|MNOPQ|QPONM|NOPQR|RQPON|OPQRS|SRQPO|PQRST|TSRQP|QRSTU|UTSRQ|RSTUV|VUTSR|STUVW|WVUTS|TUVWX|XWVUT|UVWXY|YXWVU|VWXYZ|ZYXWV)/i
export const REG_ASCII_SYMBOL = /([!-/:-@[-`{-~])/g

export const REG_UPPER_LETTER = /[A-Z]/
export const REG_LOWER_LETTER = /[a-z]/
export const REG_NUMBER = /[0-9]/
export const REG_SPACE = /\s/g

// 邮编
export const REG_POSTAL = /^[0-9]{6}$/

// QQ号
export const REG_QQ = /^[1-9]\d{4,11}$/

// 身份证
export const ID_CARD_POSTAL = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
export const ID_CARD_HONG_KONG = /^([A-Z]{1}|[A-Z]{2})([0-9]{6})(\([0-9A]\))$/ // Hong Kong
export const ID_CARD_MACAU = /^([1|5|7])([0-9]{6})(\([0-9Aa]\))$/ // Macau
export const ID_CARD_TAIWAN = /^([A-Z])([1-2]{1})([0-9]{8})$/ // Taiwan

// 15或18位身份证
export const ID_CARD_ALL = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X)$)/

// 18位数字或大写字母
export const REG_NUMBER_UPPER_LETTER = /^[A-Z0-9]{18}$/

// 用户名
export const GLOBAL_USER_NAME = /^[a-zA-Z0-9_@-]{6,30}$/

// 网址
export const WEBSITE_URL = /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]*\.)+[a-zA-Z]+)(:\d+)?([-a-zA-Z\/\d?%_&#=]*)?$/

export const WEBSITE_URL_SIMPLE = /^((https?:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]*\.)+[a-zA-Z]+)(:\d+)?([-a-zA-Z\/\d?%_&#=]*)?$/
